.surcontainer {
    top: 0;
    left: 0;

    z-index: 1;
}

@keyframes flyin {
    from {left: -100px;}
    to {left: calc(var(--bs-gutter-x) * 0.5);}
}

@keyframes flyout {
    from {left: calc(var(--bs-gutter-x) * 0.5);}
    to {left: -100px;}
}

.container {
    top: 10px;
    left: -5vw;

    animation-name: flyin;
    animation-duration: 0.5s;
}

.hold {
    left: calc(var(--bs-gutter-x) * 0.5);
}

.flyout {
    animation-name: flyout;
    animation-duration: 0.5s;
}