.surcontainer {
    position: absolute;
    top: 10px;
    right: calc(var(--bs-gutter-x) * 0.5);
    width: 33%;
    height: 25%;
}

@keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
}

.container{
    position: absolute;
    top: 2%;
    right: 2%;
    width: 20%;

    cursor: pointer;

    animation-name: fadein;
    animation-duration: 0.5s;
}

.img {
    width: 100%;
}

