.hcpsurcontainer {
    display: flex;
    width: 100%;
}

.hcpcontainer {
    width: 100%;
    text-align:center;
}

.hcpcontainer .react-colorful {
    width: 80%;
    height: 100px;

    margin-left:10%;
    margin-right:10%;
}