.container {
    width:2vw;
    height: 3vw;
    
    margin-top:0.5vw;
    margin-right: 0.5vw;
    float:right;
}

.img {
    width:100%;
    background-color: #B2B5B8;
    border-radius: 5px;
    cursor:pointer;
}