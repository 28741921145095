.common_surcontainer {
    position: fixed;

    width: 12vw;
    height: 12vw;
}

.common_container {
    position: absolute;

    height: 5vw;
    width: 5vw;

    display: flex; 
    justify-content:center;
    align-items: center;

    cursor: pointer;
}

.common_circle {
    background-color: #dee2e6;
    border-radius: 20%;

    min-width: 0;
    width:80%;

    max-width:64px;
    max-height:64px;
}

.common_img {
    width:100%;
}