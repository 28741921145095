.surcontainer {
    bottom: 0;
    right: 0;
}

@keyframes flyin {
    from {right: -100px;}
    to {right: calc(var(--bs-gutter-x) * 0.5);}
}

@keyframes flyout {
    from {right: calc(var(--bs-gutter-x) * 0.5);}
    to {right: -100px;}
}

.container {
    bottom: 10px;
    right: -5vw;

    animation-name: flyin;
    animation-duration: 0.5s;
}   

.hold {
    right: calc(var(--bs-gutter-x) * 0.5);
}

.flyout {
    animation-name: flyout;
    animation-duration: 0.5s;
}