.container {
    position:relative;
    padding-top:10px;
    padding-bottom:10px;
}

.sub_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}